import { EventType, MatrixEvent, MatrixEventEvent } from "matrix-js-sdk";
import { MessagingService } from "../_services/messaging.service";

export class Message {
    get sender() {
        return this.event.sender!
    }

    get content() {
        return this.event.event.content!
    }

    // private _reactions: Array<Reaction> = [];
    // get reactions() {
    //     if(this.roomCreated) return [];
    //     if(!this._reactions) {
    //         this._reactions = this.event.event.event_id ? Array.from(this.messaging.reations.get(this.event.event.event_id)?.values() || []) : [];            
    //         return this._reactions;
    //     } else {
    //         let reactions = this.event.event.event_id ? Array.from(this.messaging.reations.get(this.event.event.event_id)?.values() || []) : [];  
    //         if(reactions !== this._reactions) this._reactions = reactions;
    //         return this._reactions;
    //     }
    // }

    messageId: string;
    roomCreated = false;
    redacted = false;
    time: Date;
    type: EventType;

    constructor(
        private messaging: MessagingService,
        public event: MatrixEvent
    ) {
        this.type = <EventType>event.getType();

        if(![EventType.RoomMessage, EventType.RoomCreate].includes(this.type)) throw new Error("Wrong Event type");

        this.time = new Date(event.localTimestamp);
        // console.log('MESSAGE', event.event);

        if (this.type == EventType.RoomCreate) this.roomCreated = true;
        if ((<any>event.event)['redacted_because']) this.redacted = true;
        
        event.on(MatrixEventEvent.BeforeRedaction, (ev) => {
            this.redacted = true;
        })

        this.messageId = event.event.event_id!
    }
}