import { Injectable } from "@angular/core";
// import { init } from "@matrix-org/olm";
import * as sdk from "matrix-js-sdk";
import { ClientEvent, MatrixClient } from "matrix-js-sdk/lib/client";
import { EmittedEvents } from "matrix-js-sdk/lib/";
import { EventType, IEvent, IEventWithRoomId, ISendEventResponse, MatrixError, MatrixEvent, MsgType, Preset, Room, RoomEvent, RoomMember, Visibility } from "matrix-js-sdk/lib/matrix";
// import { WalletService } from "./wallet";
import { HttpClient } from "@angular/common/http";
// import { environment } from "src/environments/environment";
import EventEmitter from "events";
import * as gen from 'random-seed';

import { Color } from "../_objects/color.object";
import { Message } from "../_objects/message";
import { API, MATRIX, MATRIX_URL } from "../_constants/constants";
import { AuthService } from "./auth.service";
import { SystemService } from "./system.service";
import { Key } from "./crypto.service";
const ecc = require('eosjs-ecc')
import { logger } from 'matrix-js-sdk/lib/logger';
import { Serialize } from "eosjs";
import { uint64ToName } from "eosjs-account-name";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { NewMessageComponent } from "../_modals/new-message/new-message.component";
const types = Serialize.createInitialTypes();
const {nameToUint64 } = require('eosjs-account-name');


@Injectable()
export class MessagingService extends EventEmitter {
    client: sdk.MatrixClient;
    rooms: sdk.Room[] = [];
    idRooms: Map<string, sdk.Room> = new Map();
    roomMessages: Map<string, Array<Message>> = new Map();
    // reations: Map<string, Map<string, Reaction>> = new Map();
    profiles: Map<string, ProfileInfo> = new Map();
    events: Map<string, Partial<IEvent>> = new Map();

    clientReady = false
    public roomsLoaded= false

    public lastMessages: Map<string, any> = new Map();
    retryCount = 4

    constructor(
        // private wallet: WalletService,
        private auth : AuthService,
        private http: HttpClient,
        private nav : NavController,
        private toast : ToastController,
        private router : Router,
        private system : SystemService,
        private modal : ModalController
    ) {
        super();
        this.client = sdk.createClient({
            baseUrl: MATRIX_URL
        });

        // if (this.auth.user) this.init()

        // this.auth.on('login').subscribe(async ()=>{
        //     await this.init()
        // })
        
        this.auth.on('logout').subscribe(()=>{
            this.clearData()
        })
    }

    init() {
        return new Promise<MatrixClient>(async (resolve, reject)=>{
            logger.disableAll();
            let username = this.auth.user ? this.auth.user.username : undefined
            let key : Key = await this.auth.getKey()
            if(!key || !username) {
                // console.log('initialized without account or key');
                return;
            }
            let signature = ecc.sign(username, key.priv_key);
            
            this.http.post<{user_id: string; access_token: string; home_server: string; device_id: string}>(API + 'matrix-login', {signature, username}).subscribe(async (result) => {
                let { access_token, device_id, user_id } = result;
                this.client = sdk.createClient({
                    baseUrl: MATRIX_URL,
                    accessToken: access_token,
                    userId: user_id,
                    deviceId: device_id
                });
                console.log('MATRIX LOGIN for', user_id);
                this.setupListeners();
                await this.client.startClient({initialSyncLimit: 10});
                this.clientReady = true;
                resolve(this.client);
            }, async err => {
                console.log('error logging in', err);
                if (this.retryCount-- > 0){
                    await this.delay(2000)
                    this.init()
                }
                else {
                    const toast = await this.toast.create({
                        header: 'Messenger Login Failed',
                        duration: 6000,
                        position: 'bottom',
                        color: 'danger',
                        cssClass: 'my-toast',
                        buttons: [{
                            text: 'Retry',
                            handler: () => {
                                this.init()
                            }
                        }]
                    });
                    toast.present();
                    return toast;
                }
            })
        })
        
        // this.client.createRoom()
    }

    setupListeners() {
        this.client.once(ClientEvent.Sync, async (state, prevState, res) => {
            if (state === "PREPARED") {
                this.getRooms();
                this.roomsLoaded = true
                await this.client.joinRoom(`#general:` + MATRIX);
                this.emit('rooms-ready');
                console.log('Rooms Ready', this.rooms);
                // this.sendMessage('!TEUIGTTGULRoAJhaIZ:matrix.wire.foundation', 'Testing');
            } else {
                // console.log(state);
            }
        });

        this.client.on(RoomEvent.Timeline, (event, room, toStartOfTimeline) => {
            // console.log("room event", event);
            let isNew = event.localTimestamp > new Date().getTime() - 500
            // console.log(isNew, event.localTimestamp, new Date().getTime() - 500);
            this.emit('message', event);
            
            let type = event.getType();
            switch(type) {
                case EventType.RoomMessage:
                    const lastMessage = room.timeline.reverse()[0];;
                    this.lastMessages.set(room.roomId, lastMessage);

                    let msgs = this.roomMessages.get(room!.roomId);
                    let msg = new Message(this, event);
                    if(msgs){
                        if(msgs[0].time > msg.time){
                            msgs.push(msg); 
                        }
                        else {
                            if (event.sender.name != this.auth.user.username && isNew && !this.router.url.includes(this.sliceRoomId(room.roomId))){
                                this.notify({
                                    header: event.sender.name,
                                    message: event.event.content.body,
                                    roomId: this.sliceRoomId(room.roomId)
                                })
                            }
                            msgs.unshift(msg);
                        }
                    }
                    else this.roomMessages.set(room!.roomId, [msg]);
                    break;

                case EventType.RoomMember:
                case EventType.RoomCreate:
                    // console.log('ROOM CREATED', event);
                    this.getRooms();
                    break;

                default:
                    // console.log('EVENT', type);
                    break;
            }

            if(event.event.event_id)  this.events.set(event.event.event_id, event.event);
        })

        /**
         * Fires when the logged in user's membership in a room is updated.
         *
         * @param room - The room in which the membership has been updated
         * @param membership - The new membership value
         * @param prevMembership - The previous membership value
         */
        this.client.on(RoomEvent.MyMembership, async (room, membership, prevMembership)  => {
            // console.log("MyMembership", room, membership, prevMembership);
            if (membership === "invite") {
                // console.log('New invite, joining room', room.roomId);
                await this.client.joinRoom(room.roomId);
                // console.log('Joined room', room.roomId);
                await this.getRooms()
                this.emit('rooms-ready');
                let sender = undefined
                if (this.auth.user) {
                    let members = room.getMembers().filter(m => m.name != this.auth.user.username)
                    if (members.length > 0) sender = members[0].name;
                    // console.log('sender: ', sender);
                }
                this.notify({
                    header: 'New Message!',
                    message: sender ? `From: ${sender}` : undefined,
                    roomId: this.sliceRoomId(room.roomId)
                })
            }
            
        });
        this.client.on(RoomEvent.Name, (a) => {
            // console.log("Name", a);
        });
    }
    async getRooms(broadcast? : boolean) {
        this.rooms = await this.client.getRooms();
        for(let room of this.rooms) this.idRooms.set(room.roomId, room);
        if (broadcast) this.emit('rooms-ready');
    }

    refreshRooms(): Promise<Room[]>{
        // this.clearData()
        this.roomsLoaded = false;
        return new Promise(async (resolve, reject) => {
            this.rooms = await this.client.getRooms();
            for(let room of this.rooms) this.idRooms.set(room.roomId, room);
            this.roomsLoaded = true;
            resolve(this.rooms)
        })
    }

    clearData(){
        this.roomsLoaded = false;
        this.rooms = []
        this.idRooms = new Map()
        this.roomMessages = new Map()
        this.profiles = new Map()
        this.events = new Map()
        this.client.stopClient()
        this.client = undefined
    }

    getProfile(user: RoomMember) {
        let profile: ProfileInfo | null | undefined = this.profiles.get(user.userId);
        if(profile) return profile;
        else {
            let url = user.getAvatarUrl(MATRIX_URL, 150, 150, 'scale', true, true);
            let rng = gen.create(user.userId);
            profile = {
                profile: url ? url : undefined,
                color: new Color({ r: rng(255), g: rng(255), b: rng(255)}).hex
            } as ProfileInfo;
            this.profiles.set(user.userId, profile);
            return profile;
        }
    }

    async createRoom(username : string, message? : string){
        if (!this.clientReady) await this.init()
        
        return new Promise<string>(async (resolve, reject) => {
            // resolve('!JZQmXzpIZJKwQUVlpv:outeredge-matrix.wire.foundation')
            let to = `@${username}:${MATRIX}`
            let from = `@${this.auth.user.username}:${MATRIX}`

            let roomName = this.namesToRoom(username, this.auth.user.username)
            let exists = await this.checkRoomExists(roomName)
            
            if (!exists) {
                this.client.createRoom({
                    name: roomName,
                    // topic: "This is a test room",
                    visibility: Visibility.Private,
                    preset: Preset.TrustedPrivateChat,
                    invite: [to],
                    is_direct: true,
                }).then(async (response) => {
                    // console.log("Room created: ", response.room_id);
                    // Send message if exists to new room
                    if (message && message.length > 0) await this.sendMessage(response.room_id, message);
                    resolve(this.sliceRoomId(response.room_id));
                }).catch((error: MatrixError) => {
                    console.log("Error creating room: ", error.message, error.errcode);
                    if (error.message.includes("room already exists")) {
                        // console.log('room already exists');
                        // console.log(error);

                    }
                    if (error.message.includes("is already in the room")){
                        // console.log('already in room!');
                        // console.log(error);
                        reject('Already created direct message room, refreshing')
                    }
                });
            }
            // Room created:  !JZQmXzpIZJKwQUVlpv:outeredge-matrix.wire.foundation
        })
    }

    checkRoomExists(roomName : string){
        return new Promise<IEventWithRoomId | undefined>(async (resolve, reject) => {
            const searchResults = await this.client.search({
                body: {
                    search_categories: {
                        room_events: {
                            search_term: roomName,
                            filter: {
                                types: ["m.room.name"],
                            },
                        }
                    }
                },
            });
            if (searchResults.search_categories.room_events.results.length)
                resolve(<IEventWithRoomId>searchResults.search_categories.room_events.results[0].result)
            else resolve(undefined)
            // console.log("res!!", searchResults.search_categories.room_events.results);
            
            // if (searchResults?.results?.length > 0) {
            //     const roomId = searchResults.results[0].room_id;
            //     const room = await client.getRoom(roomId);
            //     console.log("The room exists:", room);
            // } else {
            //     console.log("The room does not exist.");
            // }
              
        })
    }

    async sendMessage(roomId: string, message: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            this.client.sendTextMessage(roomId, message)
                .then((x : any) => {
                    console.log("Message sent successfully");
                    resolve(x)
                }).catch((error: MatrixError) => {
                    console.log("Error sending message: ", error.message);
                });

            // let content = {
            //     body: message,
            //     msgtype: MsgType.Text
            // }
            // let result = await this.client.sendEvent(room_id, EventType.RoomMessage, content, "").catch((error) => {
            //     console.log('ERROR', error);
            // });
            // console.log('SENT MESSAGE', result);
            // if (result) { resolve(result.event_id) }
        })
    }

    /**
     * Given two Wire account names, generates a unique identifier for the pair.
     * 
     * @param a Username of one person in the conversation.
     * @param b Username of the other person in the conversation.
     * @returns A unique string to be used as an identifier for the two parties messaging.
     */
    namesToRoom(a: string, b: string): string {
        let aNum = +nameToUint64(a);
        let bNum = +nameToUint64(b);
        let numA = aNum;
        let numB = bNum; 
        if(aNum > bNum) {
            numB = aNum;
            numA = bNum;
        }
        let cont : number = (numA + numB) * (numA + numB + 1) / 2 + numA;
        let bigi : bigint = BigInt(cont);
        return bigi.toString(36)
    }

    async notify(data: ToastData) {
        let buttons : any[] = []
        buttons.push({ side: 'start', icon: 'chatbubble-ellipses' })
        if (data.linkText?.toLowerCase() == 'nav' && data.link) buttons.push({ 
            side: 'end', 
            text: 'VIEW',
            handler: async () => {
                this.nav.navigateForward(data.link!)
            } 
        })
        if (data.roomId)  buttons.push({ 
            side: 'end', 
            // icon: 'arrow-redo',
            text: data.linkText ? data.linkText : 'VIEW', 
            handler: () => { 
		        this.nav.navigateForward(['/app', 'tabs', 'chat', 'room', data.roomId])
            } 
        })

        this.system.vibrate()
        const toast = await this.toast.create({
            header: data.header,
            message: data.message,
            duration: data.duration ? data.duration : 5000,
            position: 'top',
            color: data.color ? data.color : 'lightblue',
            cssClass: 'my-notify',
            buttons
        });
        toast.present();
        return toast;
    }

    async openNewMessage(incomingSearch?  : string){
        let modal = await this.modal.create({
            component: NewMessageComponent,
            cssClass: 'main-modal',
            handle: true,
            initialBreakpoint: 0.95,
            breakpoints : [0, 0.5, 0.95],
            componentProps: incomingSearch ? { incomingSearch } : {}
        });

        modal.onDidDismiss().then((res : any) => {
            if (res && res.data && res.data.length > 0){
                setTimeout(()=>{
                    this.nav.navigateForward(['/app', 'tabs', 'chat', 'room', res.data.split(':')[0]])
                }, 500)
            }
        })
        return await modal.present(); 
    }

    sliceRoomId(room_id: string) {
        return room_id.slice(0, room_id.indexOf(':'));
    }

    delay(ms: number) { return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) }) }
}


interface ProfileInfo {
    profile?: string;
    color: string;
}


export interface ToastData {
    header: string;
    message?: string;
    icon?: string;
    link?: string;
    linkText?: string;
    duration?: number;
    color?: string;
    route? : string;
    roomId? : string;
}

