// import { User } from "../_services/auth.service"

export const API = 'https://nftla-api.wire.foundation/'
// export const API = 'http://localhost:3000/'

export const WIRE_API = 'https://api.airwire.io/'
export const HYPERION : string = "https://hyperwire.airwire.io/"
export const CHAIN : string = "https://swamprod.airwire.io/"

export const AES_KEY : string = "outeredge-secret"

// export const IPFS_GATEWAY : string = "https://ipfs.airwire.io/ipfs/"
export const IPFS_GATEWAY : string = "https://ipfs.fuego.airwire.io/"
export const IPFS_OG : string = "https://ipfs.airwire.io/ipfs/"
export const IPFS_SUFFIX : string = "/image.png"

export const EXPLORER_TRANSACTION : string = "https://explorer.airwire.io/transaction/"

export const MATRIX_URL : string = 'https://outeredge-matrix.wire.foundation'
export const MATRIX : string = 'outeredge-matrix.wire.foundation'

export const DEFAULT_SETTINGS : any = {
    dark: true,
}