	<ion-app [class.dark-theme]="system.settings.dark">
		<ion-split-pane contentId="main-content" >

			<ion-menu contentId="main-content" [disabled]="!auth.user" >
				<div class="header-img">
					<img src="assets/logo/logo_{{ system.mode }}.png" alt="">
				</div>
				<ion-content scrollY="false">
					<div class="scroll-container">
						<!-- Conference -->
						<ion-list lines="none" style="padding-top: 0 !important; ">
							<ion-list-header>
								Conference
							</ion-list-header>
							<ion-menu-toggle autoHide="false" >
                                <ng-container *ngFor="let p of appPages; let i = index">
                                    <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
                                        <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
                                        <ion-label>
                                            {{p.title}}
                                        </ion-label>
                                    </ion-item>
                                </ng-container>
                                <ion-item (click)="openMap()" detail="false">
                                    <ion-icon slot="start" name="map-outline"></ion-icon>
                                    <ion-label>
                                        Map
                                    </ion-label>
                                </ion-item>
							</ion-menu-toggle>
						</ion-list>

						<!-- Account -->
						<ion-list *ngIf="auth.user" lines="none">
							<ion-list-header>
								Account
							</ion-list-header>

							<ion-menu-toggle autoHide="false">
								<ion-item routerLink="/app/tabs/account" routerLinkActive="selected" routerDirection="root" detail="false">
									<ion-icon slot="start" name="person"></ion-icon>
									<ion-label>
										Account
									</ion-label>
								</ion-item>
								<ion-item routerLink="/app/tabs/camera" routerLinkActive="selected" routerDirection="root" detail="false">
									<ion-icon slot="start" name="camera"></ion-icon>
									<ion-label>
										NFT Camera
									</ion-label>
								</ion-item>
							</ion-menu-toggle>

							<!-- <ion-menu-toggle autoHide="false">
								<ion-item button (click)="logout()" detail="false">
									<ion-icon slot="start" name="log-out"></ion-icon>
									<ion-label>
										Logout
									</ion-label>
								</ion-item>
							</ion-menu-toggle> -->
						</ion-list>

						<!-- Sign Up / Sign In -->
						<ion-list *ngIf="!auth.user" lines="none">
							<ion-list-header>
								Account
							</ion-list-header>

							<ion-menu-toggle autoHide="false">
								<ion-item routerLink="/login" routerLinkActive="selected" routerDirection="root" detail="false">
								<ion-icon slot="start" name="log-in"></ion-icon>
								<ion-label>
									Log In
								</ion-label>
								</ion-item>
							</ion-menu-toggle>

							<ion-menu-toggle autoHide="false">
								<ion-item routerLink="/signup" routerLinkActive="selected" routerDirection="root" detail="false">
								<ion-icon slot="start" name="person-add"></ion-icon>
								<ion-label>
									Sign Up
								</ion-label>
								</ion-item>
							</ion-menu-toggle>
						</ion-list>

						<!-- Other -->
						<ion-list lines="none">
							<ion-list-header>
								Other
							</ion-list-header>
							<ion-menu-toggle autoHide="false">
								<ion-item>
									<ion-icon slot="start" name="moon-outline"></ion-icon>
									<ion-label>Dark Mode</ion-label>
									<ion-toggle [(ngModel)]="system.settings.dark" (ionChange)="toggle($event)"></ion-toggle>
								</ion-item>
							</ion-menu-toggle>
							<ion-menu-toggle autoHide="false">
								<ion-item button (click)="openIntro()" detail="false">
									<ion-icon slot="start" name="planet"></ion-icon>
									<ion-label>About NFT LA</ion-label>
								</ion-item>
							</ion-menu-toggle>
						</ion-list>

					</div>
				</ion-content>
			</ion-menu>

			<ion-router-outlet id="main-content" ></ion-router-outlet>
		</ion-split-pane>
	</ion-app>
