<ion-header>
    <ion-toolbar style="padding: .75rem 0 .5rem 0;">
        <ion-buttons slot="start">
            <ion-button color="primary" >
                <ion-spinner color="primary" name="lines-sharp-small" [class.hidden]="!searching" ></ion-spinner>
            </ion-button>
        </ion-buttons>
        <ion-title class="montserrat" style="font-size: 20px; font-weight: 600;">New Message</ion-title>
        <ion-buttons slot="end">
            <ion-button color="primary" (click)="modal.dismiss()" >
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content scrollY="false">
    <div class="chat-container">
        <ng-container *ngIf="!selectedProfile; else isSelected">
            <ion-searchbar #searchbar placeholder="Search Username" [(ngModel)]="searchFor" (ionChange)="doSearch($event)"></ion-searchbar>

            <div class="results" #overflow>
                <ng-container *ngIf="!searching && profiles.length > 0">
                    <ng-container *ngFor="let p of profiles">
                        <ion-item class="result zoomInSmall delay-child-fast" lines="full" style="--background: none;" (click)="selectProfile(p)">
                            <img [src]="p.pic" alt="" class="pfp" slot="start">
                            <ion-label>
                                <h3>{{ p.firstName }}&nbsp;{{ p.lastName }}</h3>
                                <p>@{{p.username}}</p>
                            </ion-label>
                        </ion-item>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!searching && profiles && profiles.length == 0">
                    <div class="w-100 text-center text2 mt-3">
                        No results
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-template #isSelected>
            <ion-item class="result" lines="full" style="--background: none;" (click)="reselect()">
                <img [src]="selectedProfile.pic" alt="" class="pfp" slot="start">
                <ion-label>
                    <h3>{{ selectedProfile.firstName }}&nbsp;{{ selectedProfile.lastName }}</h3>
                    <p>@{{selectedProfile.username}}</p>
                </ion-label>
            </ion-item>
        </ng-template>

        <div class="inputs">
            <textarea 
                id="message"
                appTextareaAutosize
                rows="1"
                [minRows]="1"
                [maxRows]="4"
                placeholder="Write a message..." 
                [(ngModel)]="newMessage" 
                
                name="message"
                class="message-input"
                autofocus
                (keydown.enter)="sendMessage()"
            ></textarea>
            <ion-icon [class.disabled]="!canSend" color="link" name="arrow-up-circle" (click)="sendMessage()"></ion-icon>
        </div>
    </div>

    <!-- <div class="ion-margin-top">
    <ion-label>Click the handle above to advance to the next breakpoint.</ion-label>
    </div>

    <div class="bottom-container">
        
    </div> -->
</ion-content>