import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { Key } from "./crypto.service";
import { DEFAULT_SETTINGS, API, AES_KEY, HYPERION } from '../_constants/constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
const ecc = require('eosjs-ecc');
import { Storage } from '@ionic/storage';
import { SystemService } from "./system.service";
import { Session } from "./event.service";

@Injectable()
export class AuthService  {
    private events : any = {};
    public authHeaders? : HttpHeaders
    public authSignature? : string
    private headerSet = false
    private verifiedCode = false

    constructor(
        private storage : Storage,
        private alert : AlertController,
        private nav : NavController,
        private http : HttpClient,
        private load : LoadingController,
        private system : SystemService ){

        // this.storage.create()
        if (this.user && !this.authHeaders) this.setAuthHeaders()
    }

    get user(): Profile {
        return localStorage.getItem('outeredge-user') ? JSON.parse(localStorage.getItem('outeredge-user')!) : null
    }
    get authHeader() : HttpHeaders | any {
        return this.authHeaders ? {  headers: this.authHeaders }
        : { 
            headers: new HttpHeaders({
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*'
            }) 
        }
    }
    get settings() {
        let settings = localStorage.getItem('outeredge-settings')
        if (!settings) return DEFAULT_SETTINGS
        else return JSON.parse(settings)
    }
    get signature(){
        return this.authSignature
    }
    get eventbrite(): boolean{
        return this.user && this.user.eventbrite && this.user.eventbrite != ''
    }
    get code(){
        return this.settings.code && this.settings.code === true
    }
    get authorized(){
        return this.eventbrite || this.code
    }

    setSettings(settings : any) {
        localStorage.setItem('outeredge-settings', JSON.stringify(settings))
    }
    setUser(user : any) {
        localStorage.setItem('outeredge-user', JSON.stringify(user))
    }

    login(user : Account){
        delete user.password
        delete user.passwordConfirm
        delete user.key
        
        let str = JSON.stringify(user)
        localStorage.setItem('outeredge-user', str);
        
        setTimeout(()=>{
            this.emit('login', user.username)
            this.setAuthHeaders()
        }, 500)
    }
    
    async logout() : Promise<any>{
        return new Promise(async (resolve, reject) => {
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Confirm Logout',
                message: `Are you sure you want to log out?`,
                buttons: [{
                    text: 'Logout',
                    handler: () => {
                        this.logoutHard()
                        resolve(true)
                    }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]
            });
            alert.present();
        })
        
    }

    logoutHard(){
        this.emit("logout")
        this.nav.navigateForward(['default'])
        this.clearStorage();
        localStorage.setItem('outeredge-settings', JSON.stringify(DEFAULT_SETTINGS))
    }

    register(){

    }
    

    setKey(key : Key){
        this.storage.set('key', CryptoJS.AES.encrypt(JSON.stringify(key), AES_KEY).toString())
    }
    getKey() : Promise<Key>{
        return new Promise((res, rej) => {
            this.storage.get('key').then((key) => {
                if (key){
                    try         { res(JSON.parse(CryptoJS.AES.decrypt(key, AES_KEY).toString(CryptoJS.enc.Utf8))) } 
                    catch(err)  { console.log("ERR", err) }
                }
            });
        })
    }

    setAuthHeaders(username? : string){
        return new Promise((resolve, reject)=>{
            this.generateSignature().then((signature : string)=>{
                this.authSignature = signature
                this.authHeaders = new HttpHeaders ({
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    'username': username ? username : this.user.username,
                    'signature': signature
                }) 
                this.headerSet = true
                resolve(this.authHeaders)
            })
        })
    }
    
    getNonce(username: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get(`${API}get-nonce`, { params: {username }}).toPromise().then((response: any) => {
                if(response.nonce) resolve(response.nonce);
            }).catch((err) => {
                reject(err);
            })
        })
    }
    generateSignature() : Promise<string>{
        return new Promise((resolve, reject)=>{
            if (this.user) this.getKey().then((res: Key)=>{
                resolve(ecc.sign(this.user.username, res.priv_key))
            }, err => reject('Error getting keys'))
            else reject('Not logged in')
        })
    }

    usernameExists(username : string){
        return new Promise((resolve) => {
            this.http.get(`${HYPERION}v2/state/get_account?account=${username}`).subscribe((res:any) => {
                resolve(true)
            }, (err:any) => {
                resolve(false)
            })
        })
    }

    verifyUsernameKey(){
        return new Promise(async (resolve, reject)=>{
            if (this.user){
                let public_key = (await this.getKey()).pub_key
                this.http.post(HYPERION + "v1/history/get_key_accounts", { public_key }).subscribe((res:any) => {
                    if (res.account_names.includes(this.user.username)) resolve(true)
                    else reject()
                }, err => reject(err))
            }
            else reject('Not logged in')
        })
    }

    getProfile(username : string): Promise<Profile>{
        return new Promise((resolve, reject)=>{
            this.http.get(API + 'user/' + username).subscribe((res : any)=>{
                resolve(res)
            }, err =>{
                reject(err)
            })
        })
    }

    searchProfile(query : string = ""): Promise<Profile[]>{
        return new Promise((resolve, reject)=>{
            this.http.get(API + 'searchProfile/' + query).subscribe((res : any)=>{
                // console.log(res);
                resolve(res)
            }, err =>{
                reject(err)
            })
        })
    }

    saveProfile(user : User){
        return new Promise(async (resolve, reject)=>{
            if (!this.headerSet) await this.setAuthHeaders()
            this.http.post(API + 'saveProfile/', user, this.authHeader).subscribe((res : any)=>{
                // console.log(res);
                resolve(res)
            }, err =>{
                console.log(err);
                reject(err)
            })
        })
    }

    async deleteProfile(){
        if (this.user){
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Confirm Delete',
                message: `Are you sure you want to delete this profile data?`,
                buttons: [{
                    text: 'Confirm',
                    handler: async () => {
                        const alert = await this.alert.create({
                            cssClass: 'my-custom-class',
                            header: 'Are you sure?',
                            buttons: [{
                                text: 'Delete Profile',
                                handler: async () => {
                                    const loading = await this.load.create({ spinner: 'crescent', message: `Deleting Profile...`, cssClass: 'loading-overlay', backdropDismiss: true });
                                    await loading.present();

                                    if (!this.headerSet) await this.setAuthHeaders()
                                    return new Promise((resolve, reject)=>{
                                        this.http.post(API + 'deleteProfile/', { username: this.user.username}, this.authHeader).subscribe((res : any)=>{
                                            loading.dismiss()
                                            this.logoutHard()
                                            this.nav.navigateForward(['app', 'tabs', 'schedule'])
                                            this.system.showToast({ header: "Profile deleted", color: "success", icon: 'trash-outline' });
                                            resolve(res)
                                        }, err =>{
                                            console.log(err);
                                            loading.dismiss()
                                            reject(err)
                                        })
                                    })
                                }
                            },{
                                text: 'Cancel',
                                role: 'cancel',
                            }]
                        });
                        alert.present();
                    }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]
            });
            alert.present();
        }
    }

    clearStorage(){
        this.storage.clear()
        localStorage.removeItem('outeredge-user')
        localStorage.removeItem('outeredge-settings')
        this.authHeaders = undefined
        this.authSignature = undefined
    }

    async verifyEventbrite(){
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Verify Ticket',
            message: `Please verify that your email associated with your account matches the email you used to purchase your ticket on Eventbrite.<br><br>
                Clicking <i>Continue</i> will verify with Eventbrite if your email matches an order. Once verified, you will then have access to the full Outer Edge App.`,
            buttons: [{
                text: 'Continue',
                handler: async () => {
                    const loading = await this.load.create({ spinner: 'crescent', message: `Verifying...`, cssClass: 'loading-overlay', backdropDismiss: true });
                    await loading.present();

                    this.checkEventbrite().then((res)=>{
                        loading.dismiss()
                        if (res){
			                this.system.showToast({ header: "Ticket Verified! Airdrop received", message: `Thank you for verifying your ticket, you now have full access to the Outer Edge App! Check your NFT wallet on the profile for a special airdrop to ticket holders.`, color: "success", duration: 7000 });
                        }
                        else {
                            console.log('error');
			                this.system.showToast({ header: "Unable to verify email on ticket order", message: `Not ticket was found matching the email address: '${this.user.email}', please double check your order and try again!`, color: "danger" });
                        }
                    }, err =>{
                        loading.dismiss()
                        console.log(err);
                        this.system.showToast({ header: "Something went wrong verifying...", message: `Please restart the app and try again`, color: "danger" });
                    })
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }
    checkEventbrite(){
        if (this.user){
            return new Promise(async (resolve, reject)=>{
                if (!this.headerSet) await this.setAuthHeaders()
                this.http.post(API + 'checkEventbrite/', { email : this.user.email}, this.authHeader).subscribe((res : any)=>{
                    // console.log(res);
                    if (res){
                        let user = this.user
                        user.eventbrite = res.id ? res.id : '123456789'
                        this.setUser(user)
                    }
                    resolve(res)
                }, err =>{
                    console.log(err);
                    reject(err)
                })
            })
        }
    }
    async verifyCode(){
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Join Event with Code',
            message: `If you have a code to join this event, please enter it below to gain access:`,
            inputs: [
                {
                    name: 'code',
                    id: 'code',
                    type: 'text',
                    label: 'Code',
                    value: '',
                    placeholder: 'Enter Code',
                }
            ],
            buttons: [{
                text: 'Join',
                handler: async (data) => {
                    // console.log(data.code);
                    if (data.code){
                        const loading = await this.load.create({ spinner: 'crescent', message: `Verifying Code...`, cssClass: 'loading-overlay', backdropDismiss: true });
                        await loading.present();

                        this.http.post(API + 'verifyCode', { code : data.code }).subscribe((res : any)=>{
                            loading.dismiss()
                            // console.log(res);
                            if (res.verify === true){
                                let settings = this.settings
                                settings.code = true
                                this.setSettings(settings)
                                this.system.showToast({ header: "Access Granted!", message: `Welcome - enjoy a limited preview of the Outer Edge App`, color: "success" });
                            }
                        }, err =>{
                            loading.dismiss()
                            console.log(err);
                            this.system.showToast({ header: "Sorry, access denied", message: `We were not able to verify this code, please double check and try again.`, color: "warning" });
                        })
                    }
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    setCode(){
        
    }

    async addFavorite(session : Session){
        if (!this.headerSet) await this.setAuthHeaders()
        this.http.post(API + 'addFavorite', { session }, this.authHeader).subscribe((res)=>{
            console.log('Added favotite', res);
        }, err => {
            console.log('Error adding favotite', err);
        })
    }

    async removeFavorite(session : Session){
        if (!this.headerSet) await this.setAuthHeaders()
        this.http.post(API +'removeFavorite', { sessionId : session.id }, this.authHeader).subscribe((res)=>{
            console.log('Removed favotite', res);
        }, err => {
            console.log('Error removing favotite', err);
        })
    }

    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface Account {
    username : string
    firstName : string
    lastName : string
    company : string
    title : string
    pic : string
    email : string
    password ? : string
    passwordConfirm ? : string
    key ? : string
}
export interface User {
    username : string
    firstName : string
    lastName : string
    company : string
    title : string
    pic : string
    email : string
    password ? : string
    passwordConfirm ? : string
    key ? : string
}
export interface Profile {
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    eventbrite?: string
    title: string,
    bio: string,
    pic: string,
    twitter: string,
    linkedin: string,
    website: string,
    company: string,
    c_industry: null | number,
    c_revenue: null | number,
    c_size: null | number,
    joined: string
}

interface HTTPResponse<T> {
    error: boolean;
    data?: T;
    message?: string;
}


// export interface Account {
//     username: string
//     email: string
//     address?: string
//     key? : string,
//     code? : number
//     joined? : string | Date
//     referral? : string
// }

export interface Referral {
    id? : number
    code : string
    reward : number
    uses? : number
    active? : 0 | 1 | boolean
}

export interface Token {
    amount: number
    contract: string
    precision: number
    symbol: string
}
