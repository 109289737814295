import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckIntro } from './providers/check-intro.service';
import { AuthGuard } from './_services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/intro',
    pathMatch: 'full'
  },
//   {
//     path: 'account',
//     loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
//   },
//   {
//     path: 'support',
//     loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
//   },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'default',
    loadChildren: () => import('./default/default.module').then(m => m.DefaultModule),
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroModule),
    canLoad: [CheckIntro]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
