import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { SystemService } from "./system.service";

@Injectable()
export class CodeGuard implements CanActivate {
    constructor(private auth : AuthService, private router: Router, private system : SystemService) {}

    canActivate(): boolean {
        if (this.auth.user && this.auth.eventbrite) return true;
        else {
            this.system.showToast({ header: "Ticket Holders Only", message: `Please purchase a ticket to view this part of the app`, color: "warning" });
            // this.router.navigate(['app', 'tabs', 'schedule'], {replaceUrl: true});
            return false;
        }
    }
}