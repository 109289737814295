import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { API } from '../_constants/constants';
import { Account, AuthService } from '../_services/auth.service';
import { CryptoService, Key } from '../_services/crypto.service';
import { Session } from '../_services/event.service';


@Injectable({
	providedIn: 'root'
})
export class UserData {
	favorites: string[] = [];
	HAS_LOGGED_IN = 'hasLoggedIn';
	HAS_SEEN_TUTORIAL = 'hasSeenIntro';

	constructor(
		private crypto : CryptoService,
		public storage: Storage,
		private http : HttpClient,
		public auth : AuthService
	) {
        if (this.auth.user){
            this.getFavorites(this.auth.user.username);
        }
    }

    getFavorites(username : string) {
        this.http.get(API + 'favorites/' + username).subscribe((res : { event_id : string }[])=>{
            if (res) for (let e of res) this.favorites.push(e.event_id);
        })
    }

	hasFavorite(session: Session): boolean {
		return (this.favorites.indexOf(session.id) > -1);
	}

	addFavorite(session: Session): void {
		this.favorites.push(session.id);
        this.auth.addFavorite(session);
	}

	removeFavorite(session: Session): void {
		const index = this.favorites.indexOf(session.id);
		if (index > -1) {
		    this.favorites.splice(index, 1);
            this.auth.removeFavorite(session);
		}
	}

	login(username: string): Promise<any> {
		return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
			// this.setUsername(username);
			return window.dispatchEvent(new CustomEvent('user:login'));
		});
	}

	signup(user: Account): Promise<any> {
		// this.crypto.generateKeySeed(user.username, user.password).then((key : Key) => {
		// 	this.http.post(API + "signup", user).subscribe((res:any) => {
		// 		// console.log(res);
		// 		this.auth.setKey(key)
		// 		this.auth.login(account)

		// 		this.contract.login(key)
		// 		// this.discord.login(user.username)
		// 		// this.router.navigateByUrl('/home');
		// 		this.modal.dismiss()
		// 		// this.auth.emit('login', account.username);
		// 		if (!this.noRedirect) this.nav.navigateForward(['dashboard', 'settings'])

		// 		setTimeout(() =>{
		// 			this.system.showToast({ header: `Greetings, ${account.username}!`, color: 'success', duration: 1500 })
		// 		}, 500)
		// 	}, error => {
		// 		loading.dismiss()
		// 		console.log(error);
		// 		let header = error?.error?.error?.what ? error?.error?.error?.what : error.error
		// 		this.system.showToast({ header, color: 'danger' })
		// 	})
		// })




		return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
			this.setUsername(user);
			return window.dispatchEvent(new CustomEvent('user:signup'));
		});
	}

	logout(): Promise<any> {
		return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
			return this.storage.remove('username');
		}).then(() => {
			window.dispatchEvent(new CustomEvent('user:logout'));
		});
	}

	setUsername(user: Account): Promise<any> {
		return this.storage.set('user', user);
	}

	getUsername(): Promise<string> {
		return this.storage.get('user').then((user) => {
			return user;
		});
	}

	isLoggedIn(): Promise<boolean> {
		return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
			return value === true;
		});
	}

	checkHasSeenIntro(): Promise<string> {
		return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
			return value;
		});
	}
}
