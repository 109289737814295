import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { of } from 'rxjs';
import { API } from "../_constants/constants";

const url = "https://outer-edge.events.lineup.ninja/events/outer-edge/event.json"
const headers : HttpHeaders = new HttpHeaders({
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*'
}) 

@Injectable()
export class EventService  {
    private events : any = {};
    private rawData: OuterEdgeImport;
    public data? : OuterEdgeData
    public contributors : Contributor[]

    constructor(private http : HttpClient) {
        // this.init()
    }

    // get events() : OuterEdgeImport {

    // }

    init(){
        console.log('INIT EVENT DATA');
        
        return new Promise((resolve, reject) => {
            // this.http.get('assets/data/events.json').subscribe((data : OuterEdgeImport)=>{
            this.http.get(API + 'events').subscribe((data : OuterEdgeImport)=>{
                this.rawData = data
                this.processRawData().then((data : OuterEdgeData)=>{
                    this.data = data
                    this.emit('data', this.data)
                    resolve(data)
                })
            }, err => {
                console.log('Error fetching events, loading from backup', err);
                this.http.get('assets/data/backup_events.json').subscribe((data : OuterEdgeImport)=>{
                    this.rawData = data
                    this.processRawData().then((data : OuterEdgeData)=>{
                        this.data = data
                        this.emit('data', this.data)
                        resolve(data)
                    })
                })
            })
        })
        // this.http.get(url, {headers}).subscribe((data)=>{
        //     console.log(data);
        // })
    }


    processRawData() {
        console.log('Process raw event data');

        // if (!this.rawData) await this.init()
        return new Promise((resolve, reject) => {
            const contributors = this.rawData.contributors;
            const locations = this.rawData.locations;
            const sessions = this.rawData.sessions;
            
            // Create a map of contributors for easier lookup
            const contributorMap: { [id: string]: any } = {};
            contributors.forEach((contributor: any) => {
                contributor.sessions = [];
                contributorMap[contributor.id] = contributor;
            });
            
            // Create a map of locations for easier lookup
            const locationMap: { [id: string]: any } = {};
            locations.forEach((location: any) => {
                location.sessions = [];
                locationMap[location.id] = location;
            });
            
            // Process the sessions
            sessions.forEach((session: any) => {
                session.location = locationMap[session.location].name;
                session.contributors.forEach((contributorId: string) => {
                    const contributor = contributorMap[contributorId];
                    if (contributor) {
                        contributor.sessions.push(session);
                    }
                });
            });
            
            // Create the groups
            const groups = this.createTimeGroups(this.rawData);
            groups.forEach(group => {
                group.sessions.sort((a: any, b: any) => {
                    const startDateA = new Date(a.startDate);
                    const startDateB = new Date(b.startDate);
                    return startDateA.getTime() - startDateB.getTime();
                });
            });
            
            let data : OuterEdgeData = {
                schedule: [{
                    date: new Date().toISOString(),
                    groups: groups,
                }],
                contributors: contributors,
                locations: locations,
                sessions: sessions,
            };
            resolve(data)
        })
    }

    createTimeGroups(newData: any) {
        const sessions = newData.sessions;
        const groupsMap: { [key: string]: any } = {};
      
        sessions.forEach((session: any) => {
            // Format the session start date to a human-readable string
            //   const startDate = new Date(session.startDate);
            const startDate = new Date(new Date(session.startDate).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
            const timeLabel = startDate.toISOString();
      
            // If the group doesn't exist, create it
            if (!groupsMap[timeLabel]) groupsMap[timeLabel] = {
                time: startDate,
                sessions: [],
            };
      
            // Add the session to the corresponding group
            groupsMap[timeLabel].sessions.push(session);
        });
      
        // Convert the map to an array of groups and sort them by time
        const groups = Object.values(groupsMap).sort((a: any, b: any) => {
            return a.time < b.time ? -1 : a.time > b.time ? 1 : 0;
        });

        return groups;
    }
      
    // sortGroupsByDate(groups: any[]) {

    // }

    // getSpeaker(id : string){
    //     return new Promise(async (resolve, reject) => {
    //         // if (!this.data) await this.init()
    //         let x = this.data.contributors.filter((c: Contributor) => { return c.id == id } )
    //         if (x.length > 0) resolve(x[0])
    //     })
    // }

    // getContributors() {
        // return new Promise(async (resolve, reject) => {
        //     if (!this.rawData) await this.init()
        //     this.contributors = this.rawData.contributors;
        //     // const contributors: any[] = this.rawData.contributors;
        //     // const sessions: any[] = this.rawData.sessions;
    
        //     this.rawData.contributors.forEach(contributor => {
        //         const contributorSessions = this.rawData.sessions.filter(session =>
        //             session.contributors.includes(contributor.id)
        //         );
        //         contributor.sessions = contributorSessions;
        //     });
    
        //     resolve(this.rawData.contributors);
        // })
    // }

    // populateContributorSessions(){
    //     return new Promise(async (resolve, reject) => {
    //         await this.rawData.contributors.forEach(contributor => {
    //             const contributorSessions = this.rawData.sessions.filter(session =>
    //                 session.contributors.includes(contributor.id)
    //             );
    //             contributor.sessions = contributorSessions;
    //         });
    //         resolve(this.rawData.contributors);
    //     })
    // }

    timezone(d : Date){
        return new Date(new Date(d).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    }

    location(locationId: string): string {
        console.log(locationId);
        
        // Make sure the locations data is included in the this.rawData object
        if (this.rawData && this.rawData.locations) {
            // Find the matching location by its ID
            const location = this.rawData.locations.find(loc => loc.id === locationId);
            console.log(location);
            
            // Return the location name if found, otherwise return 'Unknown Location'
            return location ? location.name : 'Unknown Location';
        }
    
        // If the data object or locations data is not available, return 'Unknown Location'
        return 'Unknown Location';
    }

    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
  
}


export interface OuterEdgeData {
    schedule: [{
        date: string
        groups: TimeGroup[],
    }],
    contributors: Contributor[],
    locations: Location[],
    sessions: Session[],
}
export interface TimeGroup {

}

export interface OuterEdgeImport {
    /**
     * Contributors are the speakers for the event
     */
    contributors: Contributor[];
    /**
     * Labels, aka tags, can be assigned to contributors, sessions, tracks, resources and locations
     */
    labels: Label[];
    /**
     * Locations are where sessions take place
     */
    locations: Location[];
    /**
         * Resources are things that sessions need. Locations can have resources assigned to them.
         * Sessions are then required to be scheduled in a location with matching resources.
         */
    resources: Resource[];
    /**
     * Roles model the relationship between contributors and sessions, eg Chair, Panel Member etc...
     */
    roles: Role[];
    /**
     * Sessions are sessions....
     **/
    sessions: Session[];
    /**
         * A session can take place in one or many tracks.
         */
    tracks: Track[];
}
export interface Contributor {
    /**
     * The UUID for the speaker
     */
    id: string;
    /**
     * The speaker's title eg Mr, Mrs, Mx...
     */
    honorific: string | null;
    /**
     * The speaker's first name
     */
    firstName: string | null;
    /**
     * The speaker's last name
     */
    lastName: string | null;
    /**
     * Any letters after the speaker's name, eg 'Esq', 'OBE' etc...
     */
    postNominal: string | null;
    /**
     * The speaker's email. Note that some speakers may not have an email address, it is also not guaranteed to be unique.
     */
    email: string | null;
    /**
     * The speaker's company
     */
    company: string | null;
    /**
     * The speaker's job title at their company
     */
    position: string | null;
    /**
     * The speaker's phone number
     */
    phone: string | null;
    /**
     * The URL for the speaker's Twitter feed
     */
    twitter: string | null;
    /**
     * The URL for the speaker's Facebook page
     */
    facebook: string | null;
    /**
     * THe URL for the speaker's LinkedIn page
     */
    linkedin: string | null;
    /**
     * The URL for the speaker's Instagram page
     */
    instagram: string | null;
    /**
     * The URL for the speaker's Youtube channel
     */
    youtube: string | null;
    /**
     * The URL for the speaker's GitHub Profile
     */
    github: string | null;
    /**
     * The URL for the speaker's Website
     */
    website: string | null;
    /**
     * The speakers bio formatted in Markdown
     */
    bio: string | null;
    /**
     * The speakers bio formatted in HTML
     */
    bio_HTML: string;
    /**
     * A URL to the speakers headshot, 128x128px
     */
    headshotURL: string | null;
    /**
     * A URL for the speakers headshot at 256x256px
     */
    headshot2xURL: string | null;
    /**
     * The speakers availability
     */
    // availability: Availability[];
    /**
     * The UUIDs of labels assigned to the speaker
     */
    labels: string[];
    /**
     * The UUIDs of sessions assigned to the speaker
     */
    sessions: Session[];
    /**
     * A list of sessions and the role id for each session assigned to the speaker
     */
    sessionJoins: ContributorSessionJoin[];
    /**
     * Any notes for the speaker. These are generally intended to be kept private.
     */
    notes: string | null;
    /**
     * When the speaker was last modified in UTC.
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this speaker
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this speaker
     */
    customFieldData: CustomFieldData[];
}
export interface Label {
    /**
     * The UUID for the label
     */
    id: string;
    /**
     * The name of the label
     */
    name: string;
    /**
     * A description of the label formatted in Markdown
     */
    description: string | null;
    /**
     * A description of the label formatted in HTML
     */
    description_HTML: string;
    /**
     * The UUIDs of contributors (speakers) assigned to this label
     */
    contributors: string[];
    /**
     * The UUIDs of locations assigned to this label
     */
    locations: string[];
    /**
     * The UUIDs of resources assigned to this label
     */
    resources: string[];
    /**
     * The UUIDs of sessions assigned to this label
     */
    sessions: string[];
    /**
     * The UUIDs of tracks assigned to this label
     */
    tracks: string[];
    /**
     * When the label was last modified in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this label
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this label
     */
    customFieldData: CustomFieldData[];
}
export interface Location {
    /**
     * The UUID for the location
     */
    id: string;
    /**
     * The name of the location
     */
    name: string;
    /**
     * A description of the location formatted in Markdown
     */
    description: string | null;
    /**
     * A description of the location formatted in HTML
     */
    description_HTML: string;
    /**
     * The availability of the location
     */
    // availability: Availability[];
    /**
     * The UUIDs of labels assigned to this location
     */
    labels: string[];
    /**
     * The UUIDs of resources assigned to this location
     */
    resources: string[];
    /**
     * The UUIDs of sessions taking place in this location
     */
    sessions: string[];
    /**
     * The UUIDs of tracks taking place in this location
     */
    tracks: string[];
    /**
     * When the location was last modified in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this location
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this location
     */
    customFieldData: CustomFieldData[];
}
export interface Resource {
    /**
     * The UUID for the resource
     */
    id: string;
    /**
     * The name of the resource
     */
    name: string;
    /**
     * A description of the resource formatted in Markdown
     */
    description: string | null;
    /**
     * A description of the resource formatted in HTML
     */
    description_HTML: string;
    /**
     * The UUIDs of labels assigned to this resource
     */
    labels: string[];
    /**
     * The UUIDs of locations with this resource
     */
    locations: string[];
    /**
     * The UUIDs of sessions needing this resource
     */
    sessions: string[];
    /**
     * When the resource was last modified in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this resource
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this resource
     */
    customFieldData: CustomFieldData[];
}
export interface Role {
    /**
     * The UUID for the role
     */
    id: string;
    /**
     * The name of the role
     */
    name: string;
}
export interface Session {
    /**
     * The UUID for the session
     */
    id: string;
    /**
     * The name of the session
     */
    name: string;
    /**
     * A code for the session. This is created by the Event Manager, eg TECH101.
     */
    code: string | null;
    /**
     * A description of the session formatted in Markdown
     */
    description: string | null;
    /**
     * A description of the session formatted in HTML
     */
    description_HTML: string;
    /**
     * If the session is scheduled, the start date of the session in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    startDate: Date | null;
    /**
     * If the session is scheduled, the end date of the session in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    endDate: Date | null;
    /**
     * The duration of the session in milliseconds
     */
    duration: number | null;
    /**
     * If the session is scheduled, the UUID of the location it is scheduled in
     */
    location: string | null;
    /**
     * Any notes for the location. These are generally intended to be kept private.
     */
    notes: string | null;
    /**
     * The UUIDs of contributors (speakers) for this session
     */
    contributors: string[];
    /**
     * A list of contributors and the role id for each contributor assigned to the speaker
     */
    contributorJoins: SessionContributorJoin[];
    /**
     * The UUIDs of labels assigned to this session
     */
    labels: string[];
    /**
     * The UUIDs of resources assigned to this session
     */
    resources: string[];
    /**
     * The UUIDs of tracks assigned to this session
     */
    tracks: string[];
    /**
     * When the session was last modified in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this session
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this session
     */
    customFieldData: CustomFieldData[];
}
export interface Track {
    /**
     * The UUID for the track
     */
    id: string;
    /**
     * The name of the track
     */
    name: string;
    /**
     * The description of the track formatted in Markdown
     */
    description: string | null;
    /**
     * The description of the track formatted in HTML
     */
    description_HTML: string;
    /**
     * The color of the track formatted in HEX
     * eg #FF00B1
     */
    color: string | null;
    /**
     * The UUIDs of labels in this track
     */
    labels: string[];
    /**
     * The UUIDs of sessions in this track
     */
    sessions: string[];
    /**
     * When the track was last modified in UTC
     * eg 2022-02-04T18:03:03.662Z
     */
    lastModified: Date;
    /**
     * Any Data Sources referenced for this resource
     */
    references?: SourceReferences | undefined;
    /**
     * Any data for Custom Fields for this resource
     */
    customFieldData: CustomFieldData[];
}
export interface SessionContributorJoin {
    /**
     * The UUID of the contributor
     */
    contributor: string;
    /**
     * If the contributor has a specific role in this session then the UUID of their role. Null otherwise
     */
    role: string | null;
}
export interface ContributorSessionJoin {
    /**
     * The UUID of the session
     */
    session: string;
    /**
     * If the contributor has a specific role in this session then the UUID of their role. Null otherwise
     */
    role: string | null;

    /**
     * If 'Speaker Ordering' is enabled for the event, the position, or order, for this speaker in the session. Null otherwise
     */
    position: number | null;
}
export interface SourceReferences { [sourceService: string]: string[] };
export interface CustomFieldData {
    /**
     * The UUID for this data
     */
    id: string;
    /**
     * The value for this data
     */
    value: string;
    /**
     * The UUID for this field
     */
    fieldId: string;
    /**
     * The type of field
     */
    fieldType:  'text' | 'textarea' | 'number' | 'checkbox' | 'radio' | 'email' | 'url' | 'date';
    /**
     * The name of the field
     */
    fieldName: string;
}