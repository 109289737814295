import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AuthService, Profile } from '../../_services/auth.service';
import { MessagingService } from '../../_services/messaging.service';
import { SystemService } from '../../_services/system.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {
	@ViewChild('searchbar') searchbar : any;
	// @ViewChild('newmessage') newmessage : any;
    @Input() incomingSearch? : string
	
	newMessage : string
	timeout? : NodeJS.Timeout

	profiles : Profile[]
	selectedProfile? : Profile
	searchFor? : string

	searching = false;

	get canSend(){
		return this.selectedProfile && this.newMessage && this.newMessage.length > 0;
	}

constructor(
		public modal : ModalController,
		public auth : AuthService,
		private messaging : MessagingService,
		private nav : NavController,
		private system : SystemService ){}

	ngOnInit(): void {
        if (this.incomingSearch){ 
            this.searching = true
            this.searchFor = this.incomingSearch;
            this.auth.searchProfile(this.searchFor).then((res)=>{
                if (JSON.stringify(this.profiles) != JSON.stringify(res)) this.profiles = res
                this.searching = false;
            })
        }
	}

	ionViewDidEnter(): void {
		// this.timeout = setTimeout(() => {
            this.searchbar?.setFocus()
        // }, 500);
		if (this.auth.user && !this.incomingSearch){
			this.auth.searchProfile().then((res)=>{
				this.profiles = res.filter((profile : Profile) => profile.username != this.auth.user.username);
			})
		}
	}

	onSendMessage(ev: SubmitEvent) {
		ev.preventDefault();
		// console.log(this.newMessage);
		
		// if(this.roomID && this.message)
		// this.messaging.sendMessage(this.roomID, this.message).then(() => {
		// 	this.message = undefined;
		// }).catch((err) => {

		// });
	}
	
	doSearch(e : any){
		let query = e.target.value
		if (query && query != ''){
			this.searching = true;

			clearTimeout(this.timeout);
			// this.timeout = setTimeout(async ()=>{
					this.auth.searchProfile(query).then((res)=>{
						// console.log(">",res);
						this.searchFor = query
						if (JSON.stringify(this.profiles) != JSON.stringify(res)) this.profiles = res.filter((profile : Profile) => profile.username != this.auth.user.username);
						this.searching = false;
					})
            // }, 500);
        }
        else this.auth.searchProfile().then((res)=>{
            this.profiles = res.filter((profile : Profile) => profile.username != this.auth.user.username);
        })
    }
	
	selectProfile(profile : Profile){
		// console.log(profile);
		let roomName = this.messaging.namesToRoom(this.auth.user.username, profile.username)
		// console.log(profile.username, this.auth.user.username, roomName);
		// console.log(this.messaging.rooms);
		this.messaging.checkRoomExists(roomName).then((res)=>{
			// console.log(res);
		})
		let exists = this.messaging.rooms.filter((room) => { return room.name === roomName })

		if (exists.length > 0) {
			this.modal.dismiss();
			this.nav.navigateForward(['/app', 'tabs', 'chat', 'room', this.messaging.sliceRoomId(exists[0].roomId)])
		}
		else {
			this.selectedProfile = profile;
			setTimeout(()=>{

				document.getElementById('message')?.focus();
			}, 500)
		}
	}
	reselect(){
		this.selectedProfile = undefined;
		this.timeout = setTimeout(() => {
			this.searchbar?.setFocus()
		}, 1000);

	}

	sendMessage(){
		this.messaging.createRoom(this.selectedProfile.username, this.newMessage).then((roomId) => {
			// console.log(roomId);
			this.modal.dismiss(roomId)
			this.messaging.emit('refresh-rooms')
		}, err => {
			// console.log(err);
			this.system.showToast({ header: err, color: 'warning'})
			this.messaging.emit('refresh-rooms')
			this.modal.dismiss()
        })
	}
}
