import { Directive, ElementRef, HostBinding, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[appTextareaAutosize]"
})
export class appTextareaAutosizeDirective {
    @Input() minRows = 1;
    @Input() maxRows = 4;

    private input: HTMLTextAreaElement;
    private offsetHeight = 0;
    private readonly avgLineHeight = 20;

    constructor(private element: ElementRef) {
        // console.log('DIRECTIVE CONSTRUCTOR', this.element.nativeElement);
        
        this.input = this.element.nativeElement;
        this.input.style.lineHeight = '20px';
        this.input.addEventListener('keypress', this.onKeyPress);
    }

    onKeyPress = this.keyPress.bind(this)
    keyPress (event: KeyboardEvent) {
        if(event.key == "Enter" && !event.shiftKey) {
            event.preventDefault();
            if(this.input.form) {
                this.input.form.requestSubmit();
            }
            this.input.rows = 1;
            this.offsetHeight = 0;
        }
    }

    
    @HostListener("input") 
    onInput(): void {
        if(this.offsetHeight <= 0) {
            this.offsetHeight = this.input.scrollHeight;
        }

        this.input.rows = this.minRows;

        const rows = Math.floor(
            (this.input.scrollHeight - this.offsetHeight) / this.avgLineHeight
        );

        const rowsCount = this.minRows + rows;

        this.input.rows = rowsCount > this.maxRows? this.maxRows : rowsCount;
    }
}