import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { API, DEFAULT_SETTINGS, IPFS_GATEWAY, IPFS_SUFFIX, WIRE_API } from '../_constants/constants';
import { AuthService } from './auth.service';
// import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
// import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Browser } from '@capacitor/browser';
// import { Clipboard } from '@ionic-native/clipboard/ngx';
import { HttpClient } from '@angular/common/http';
import { Camera, CameraResultType } from '@capacitor/camera';
import { DomSanitizer } from '@angular/platform-browser';
// import { LoginComponent } from '../_modals/login/login.component';
// import { RegisterComponent } from '../_modals/register/register.component';
// import { Share } from '@capacitor/share';

const urlRegex = new RegExp("/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i")

@Injectable()
export class SystemService {
    private events: any = {};

    constructor(
        private platform : Platform,
        public toast: ToastController,
        private modal: ModalController,
        private alert : AlertController,
        private load : LoadingController,
        private nav : NavController,
        private router: Router,
        private http : HttpClient,
        // private clipboard : Clipboard,
		private sanitizer : DomSanitizer ){

        // if (this.settings) this.toggleDark(this.settings.dark)
        // else this.setSettings(DEFAULT_SETTINGS)
    }

    get mode(){
        return this.settings.dark ? 'dark' : 'light'
    }
    get modeInvert(){
        return this.settings.dark ? 'light' : 'dark'
    }

    get settings() {
        let settings = localStorage.getItem('outeredge-settings')
        if (!settings) return DEFAULT_SETTINGS
        else return JSON.parse(settings)
    }

    setSettings(settings : any) {
        localStorage.setItem('outeredge-settings', JSON.stringify(settings))
    }

    // setKeyboard(dark: boolean) {
    //     if ((window as any).Capacitor.isPluginAvailable('Keyboard'))
    //         Keyboard.setStyle({ style: dark ? KeyboardStyle.Dark : KeyboardStyle.Light })
    // }

    toggleDark(x : boolean){
        // let toggle : boolean = x ? x : !document.body.classList.contains('dark')
		// document.body.classList.toggle('dark', toggle);
		// this.setKeyboard(x)
        let settings = this.settings
        settings.dark = x
        this.setSettings(settings)
        this.emit('toggle-dark', x)
	}

    vibrate(style? : any) {
        // if (!style) style = 'light'
        // if ((window as any).Capacitor.isPluginAvailable('Haptics'))
        //     Haptics.impact({ style: style.toUpperCase() })
    }
    vibrateImpact() {
        // if ((window as any).Capacitor.isPluginAvailable('Haptics'))
        //     Haptics.vibrate()
    }
    vibrateSelection() {
        // if ((window as any).Capacitor.isPluginAvailable('Haptics')) {
        //     Haptics.selectionStart();
        //     Haptics.selectionChanged();
        //     Haptics.selectionEnd();
        // }
    }
    hapticsSelectionStart() {
        // if ((window as any).Capacitor.isPluginAvailable('Haptics'))
        //     Haptics.selectionStart();
    }
    hapticsSelectionChanged() {
        // if ((window as any).Capacitor.isPluginAvailable('Haptics'))
        //     Haptics.selectionChanged();
    }
    hapticsSelectionEnd() {
        // if ((window as any).Capacitor.isPluginAvailable('Haptics'))
        //     Haptics.selectionEnd();
    }

    async showToast(data: ToastData) {
        let buttons : any[] = []
        if (data.icon) buttons.push({ side: 'start', icon: data.icon })
        if (data.linkText?.toLowerCase() == 'nav' && data.link) buttons.push({ 
            side: 'end', 
            text: 'VIEW',
            handler: async () => {
                this.nav.navigateForward(data.link!)
            } 
        })
        // else if (data.linkText?.toLowerCase() == 'login') buttons.push({ 
        //     side: 'end', 
        //     text: data.linkText,
        //     handler: async () => {
        //         const modal = await this.modal.create({
        //             component: LoginComponent,
        //             canDismiss: true,
        //             cssClass: 'main-modal'
        //         });
        //         return await modal.present(); 
        //     } 
        // })
        // else if (data.linkText?.toLowerCase() == 'register') buttons.push({ 
        //     side: 'end', 
        //     text: data.linkText,
        //     handler: async () => {
        //         const modal = await this.modal.create({
        //             component: RegisterComponent,
        //             canDismiss: true,
        //             backdropDismiss: false,
        //             cssClass: 'main-modal2'
        //         });
        //         return await modal.present(); 
        //     } 
        // })
        else if (data.link) buttons.push({ 
            side: 'end', 
            // icon: 'arrow-redo',
            text: data.linkText ? data.linkText : 'VIEW', 
            handler: () => { window.open(data.link!, '_blank') } 
        })


        const toast = await this.toast.create({
            header: data.header,
            message: data.message,
            duration: data.duration ? data.duration : 4000,
            position: 'bottom',
            color: data.color ? data.color : 'dark',
            cssClass: 'my-toast',
            buttons
        });
        toast.present();
        return toast;
    }

    async openLink(url: string, shouldInclude? : string) {
        this.vibrate()
        if (url.includes('mailto')){
            window.open(url)
            return
        }
        if(shouldInclude == 'linkedin' && !url.includes('linkedin')){
            url = 'https://www.linkedin.com/in/' + url
        }
        if(shouldInclude == 'twitter' && !url.includes('twitter')){
            url = 'https://twitter.com/' + url
        }
        if (!url.includes('https://') && !url.includes('http://')) url = 'https://' + url
        await Browser.open({ toolbarColor: '#131725', url })
    }

    get isMobile(): boolean {
        return (this.platform.is('android') || this.platform.is('ios') && !this.platform.is('desktop'));
    }

    async copyClipboard(str : string, name? : string, showMessage : boolean = false){
        // this.clipboard.copy(str).then((text: string) => {}, (reject: string) => {
        //     if (navigator.clipboard){
        //         try { navigator.clipboard.writeText(str).then(() => {}); }
        //         catch (err){ console.log("ERR"); }
        //     }
        // });

        // const toast = await this.toast.create({
        //     header: name ? `${name} copied to clipboard` : `Copied to clipboard`,
        //     message: showMessage ? str : undefined,
        //     duration: 2000,
        //     position: 'bottom',
        //     color: 'success',
        //     cssClass: 'my-toast-copy',
        //     buttons: [{ side: 'end', icon: 'copy-outline' }]
        // });
        // toast.present();
        // return toast;
    }

    copied(str : string){
        this.showToast({ header: `${str} copied to clipboard`, color: 'success', icon: 'copy-outline'})
    }

    ipfs(cid : string){
        return IPFS_GATEWAY + cid + IPFS_SUFFIX
    }

    async takePicture(fullUrl? : boolean, noLoad : boolean = false, wire = false) : Promise<string> {
        return new Promise(async (resolve, reject)=>{
            const image = await Camera.getPhoto({
                quality: 100,
                allowEditing: true,
                resultType: CameraResultType.Base64,
                saveToGallery: true
            });
            let blob = this.b64toBlob(image.base64String, `image/${image.format}`);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = async (e) => {
                // let url = reader.result as string;
                // let message = `<img src="${url}" class="profile-xl light-border">`
                // let header = "Upload File to IPFS"

                // const alert = await this.alert.create({
                //     cssClass: 'custom-alert',
                //     header,
                //     message,
                //     buttons: [
                //         {
                //             text: 'Upload',
                //             handler: () => {
                //                 this.alert.dismiss()
                                if (wire) this.uploadFileWire(blob, noLoad).then((cid : string)=>{
                                    // console.log(cid);
                                    // console.log(cid);
                                    resolve(fullUrl ? IPFS_GATEWAY + cid : cid)
                                })
                                else this.uploadFile(blob, noLoad).then((cid : string)=>{
                                    // console.log(cid);
                                    // console.log(cid);
                                    resolve(fullUrl ? IPFS_GATEWAY + cid : cid)
                                })
                //             }
                //         },
                //         {
                //             text: 'Cancel',
                //             role: 'cancel'
                //         }, 
                //     ],
                //     translucent: true
                // });
                // await alert.present();
            };
        })
    }

    async uploadFile(blob : Blob, noLoad? : boolean, title: string = 'file', ): Promise<string>{
        let loading : any
        if (noLoad){
            loading = await this.load.create({ spinner: 'crescent', message: `Uploading ${title} to IPFS...`, cssClass: 'loading-overlay', backdropDismiss: true });
            await loading.present();
        }

        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', blob, 'img');
            this.http.post(WIRE_API + "addFileSimple", data).subscribe((res : any) => {
                // console.log(res);
                if (loading) loading.dismiss()
                if (res.cid) resolve(res.cid)
                else (reject("Something went wrong..."))
            }, (err:any) => {
                if (loading) loading.dismiss()
                console.log(err);
                reject(err)
            })
        })
    }

    async uploadFileWire(blob : Blob, noLoad? : boolean, title: string = 'file', ): Promise<string>{
        let loading : any
        if (noLoad){
            loading = await this.load.create({ spinner: 'crescent', message: `Uploading ${title} to IPFS...`, cssClass: 'loading-overlay', backdropDismiss: true });
            await loading.present();
        }

        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', blob, 'img');
            this.http.post(WIRE_API + "addFile", data).subscribe((res : any) => {
                // console.log(res);
                if (loading) loading.dismiss()
                if (res.cid) resolve(res.cid)
                else (reject("Something went wrong..."))
            }, (err:any) => {
                if (loading) loading.dismiss()
                console.log(err);
                reject(err)
            })
        })
    }

    b64toBlob(b64Data : any, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
     
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
        
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
     
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    titlecase(inputString: string): string {
        // Split the input string into an array of individual words
        const words = inputString.split(' ');
      
        // Map over the array of words and capitalize the first letter of each word
        const capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
      
        // Join the capitalized words back into a single string
        const outputString = capitalizedWords.join(' ');
      
        // Return the final output string
        return outputString;
    }


    to_ATTRIBUTE_MAP(key: string, value: string, type: string) {
        let newType
        let newValue

        switch (type) {
            case 'bool':
                newType = "uint8"
                newValue = value ? 1 : 0
                break;

            case 'float':
                newType = "float"
                newValue = value
                break;

            case 'int':
                newType = "uint64"
                newValue = +value
                break;

            case 'string':
            case 'image':
            case 'file':
                newType = "string"
                newValue = value
                break;

            default:
                newType = type
                newValue = value
                break;
        }
        return { "key": key, "value": [newType, newValue] }
    }

    delay(ms: number) { return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) }) }

    on(event: string) : any {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)

        else this.events[event] = [sub]
        return sub
    }
    emit(event: string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }

    timezone(utc : string) : Date{
        return new Date(new Date(utc).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    }

    // async getStoreFIlters(){
	// 	let filters : any = {}

	// 	await this.filters.forEach(f => { 
	// 		let arr = f.options.filter(o => o.checked).map(m => m.name) 
	// 		if (arr.length) filters[f.title] = arr
	// 	});

	// 	if (this.outeredgeguideSearch) filters['Search'] = this.outeredgeguideSearch

	// 	return new Promise((resolve, reject)=>{
	// 		// resolve(this.sampleCourses)
	// 		this.http.post(API + 'getouteredgeGuideFilters/', {filters}, this.auth.authHeader).subscribe((res : any)=>{
	// 			this.emit('get-outeredgeguides', res as outeredgeGuide[])
	// 			resolve(res as outeredgeGuide[])
	// 		}, err => {
	// 			reject(err)
	// 		})
	// 	})
	// }
}


export interface ToastData {
    header: string;
    message?: string;
    icon?: string;
    link?: string;
    linkText?: string;
    duration?: number;
    color?: string;
    route? : string;
}

export interface ShopFilter {
    title: string
    options: ShopFilterOption[]
}
export interface ShopFilterOption {
    name : string
    checked : boolean
}