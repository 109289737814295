import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
let API = "https://fn.airwire.io/"

@Injectable()
export class NftService {
    constructor(private http : HttpClient) {

    }

    getNFts(username : string): Promise<ApiAsset[]>{
        // https://fn.airwire.io/docs/#/assets/get_wireassets_v1_assets
        let ENDPOINT = "wiremarket/v1/assets"
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponse<ApiAsset>>(API + ENDPOINT + `?owner=${username}&page=1&limit=10&order=desc&sort=updated`).subscribe(
                async (res) => { 
                    let sale_ids : (string | null)[] = res.data.map((a : ApiAsset)=>{ return a.sales && a.sales.length ? a.sales[0].sale_id : null })
                    resolve(res.data)
                }, 
                (err : any) => { reject(err) }
            )
        })
    }
}

export interface AssetData {
    asset_id? : string
    authorized_minter: string
    collection_name : string
    schema_name : string
    encrypted: number
    edition_id : number
    royalties: any[],
    immutable_data : any,
    mutable_data: any[],
    tokens_to_back: any[]
}

// Data structures
export interface ApiAsset {
    asset_id: string
    auctions: { market_contract: 'wire.market', auction_id: string }[]
    auction?: any
    backed_tokens: []
    burned_at_block: null | string
    burned_at_time: null | string
    burned_by_account: null | string
    collection: any
    contract: string
    data: any
    immutable_data: any
    is_burnable: boolean
    is_transferable: boolean
    minted_at_block: string
    minted_at_time: string
    mutable_data: {}
    name: string
    owner: string
    sales: { market_contract: 'wire.market', sale_id: string }[]
    sale?: any
    chainSale?: any
    schema: any
    template: null
    template_mint: string
    transferred_at_block: string
    transferred_at_time: string
    updated_at_block: string
    updated_at_time: string
    edition_id? : number
    mint_index? : number
    minter?: string
    thumbnail?: string
}

export interface ApiResponse<T> {
    success: boolean
    data: T[]
    query_time: number
}
export interface ApiResponseSingle<T> {
    success: boolean
    data: T
    query_time: number
}
export interface AttributeValueMap {
    [key: string]: any
}
