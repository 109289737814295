import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { AuthService } from './_services/auth.service';
import { CryptoService } from './_services/crypto.service';
import { SystemService } from './_services/system.service';
import { ContractService } from './_services/contract.service';
import { MessagingService } from './_services/messaging.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeagoModule } from 'ngx-timeago';
import { AuthGuard } from './_services/auth.guard';
import { EventService } from './_services/event.service';
import { NotifyService } from './_services/notify.service';
import { NftService } from './_services/nft.service';
import { CodeGuard } from './_services/code.guard';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    BrowserAnimationsModule,
    TimeagoModule.forRoot(),
  ],
  declarations: [AppComponent],
  providers: [
    AuthService,
    CryptoService,
    SystemService,
    ContractService,
    MessagingService,
    EventService,
    NotifyService,
    NftService,
    AuthGuard,
    CodeGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
