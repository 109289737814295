import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { API } from '../_constants/constants';
import { NgZone } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {

    constructor(
        private router: Router,
		private platform: Platform,
        private auth : AuthService,
        private http : HttpClient,
        private nav : NavController,
        private ngZone: NgZone ) { }

    init(username: string) {
        // console.log('Init Push');
        
        if (this.platform.is('capacitor')){
            this.registerPush(username);
        } 
        // this.saveToken('joshglogau', 'ddLLd-vSWEZkmtjc0q7ec0:APA91bG9pseYTdw7O8KNbh3izYByytn_zQnBgAS3JxgEEVZRJ9wviGF4yEwOrKhtPoNTSnS3rnxNMIeICOiB8EJNSLWM9NLBKCmufXiRx7Q3j7qVPPmYdR7h33_lX7dh9CbVtEQZPp99')
    }

    private registerPush(username : string) {
        // console.log('Register Push for', username);

        PushNotifications.requestPermissions().then((permission) => {
            // console.log('PERMISSION: ' + permission.receive);
            
            // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
            if (permission.receive == 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // console.log('Not granted');
                // No permission for push granted
            }
        });

        PushNotifications.addListener(
            'registration',
            (token: Token) => {
                console.log('My token: ' + token.value);
                this.saveToken(username, token.value);
            }
        );

        PushNotifications.addListener('registrationError', (error: any) => {
            // console.log('Error: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
                // console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: ActionPerformed) => {
                const data = notification.notification.data;
                // console.log('Action performed: ' + JSON.stringify(notification.notification));
                // console.log('Data', JSON.stringify(data));
                
                if (data.event_id) {
                    setTimeout(()=>{
                        this.ngZone.run(() => {
                            this.nav.navigateForward(['app', 'tabs', 'schedule', 'session', data.event_id]);
                        });
                    }, 500)
                }
                else if (data.speaker_id) {
                    setTimeout(()=>{
                        this.ngZone.run(() => {
                            this.nav.navigateForward(['app', 'tabs', 'speakers', 'speaker-details', data.speaker_id]);
                        });
                    }, 500)
                }
                else if (data.username) {
                    setTimeout(()=>{
                        this.ngZone.run(() => {
                            this.nav.navigateForward(['app', 'tabs', 'account', data.username]);
                        });
                    }, 500)
                }
                else if (data.camera) {
                    setTimeout(()=>{
                        this.ngZone.run(() => {
                            this.nav.navigateForward(['app', 'tabs', 'camera']);
                        });
                    }, 500)
                }
                else if (data.room_id) {
                    setTimeout(()=>{
                        this.ngZone.run(() => {
                            this.nav.navigateForward(['app', 'tabs', 'chat', 'room', data.room_id]);
                        });
                    }, 500)
                }
                else setTimeout(()=>{
                    this.ngZone.run(() => {
                        this.nav.navigateForward(['app', 'tabs', 'schedule']);
                    });
                }, 500)
            }
        );
    }

    saveToken(username : string, token : string) {
        let platform = this.platform.is('ios') ? 'ios' : this.platform.is('android') ? 'android' : 'other'
        this.http.post(API + 'saveToken', { username, token, platform }).subscribe((response) => {
            // console.log(response);
        }, err => {
            // console.log(err);
        })
    }
}