import { Injectable } from "@angular/core";
// import * as CryptoJS from "crypto-js";
// import { AuthService } from "./auth.service";
const ecc = require('eosjs-ecc')
const pbkdf2 = require('pbkdf2')

@Injectable()
export class CryptoService {
    constructor(
        // public auth : AuthService
        ){
    }

    generateKeys() : Promise<Keys>{
        let keys : Keys;
        return new Promise(async (res:any) => {
            ecc.randomKey().then((ActivePrivateKey : string) => {
                ecc.randomKey().then((OwnerPrivateKey : string) => {
                    keys = {
                        active: {
                            priv_key : ActivePrivateKey,
                            pub_key : ecc.privateToPublic(ActivePrivateKey),
                        },
                        owner: {
                            priv_key : OwnerPrivateKey,
                            pub_key : ecc.privateToPublic(OwnerPrivateKey),
                        }
                    }
                    // console.log(keys);
                    res(keys)
                })
            })
        })
    }
    generateKey() : Promise<Key>{
        let key : Key;
        return new Promise(async (res:any) => {
            ecc.randomKey().then((newKey : string) => {
                key = {
                    priv_key : newKey,
                    pub_key : ecc.privateToPublic(newKey),
                }
                // console.log(key);
                res(key)
            })
        })
    }
    generateKeySeed(username : string, password : string) : Promise<Key>{
        let seed = pbkdf2.pbkdf2Sync(password, username, 1, 32, 'sha512').toString('hex')
        let priv: string = ecc.seedPrivate(seed);
        return new Promise(async (res)=> {
            let key : Key = {
                priv_key : priv,
                pub_key : ecc.privateToPublic(priv)
            }
            // console.log(key);
            res(key)
        })
    }
	privateToPublic(privateKey : string){
        try {
            let publicKey = ecc.privateToPublic(privateKey);
            return publicKey
        }
        catch {
            return 'Invalid Private Key'
        }
    }

    // generateSignature(){
    //     return new Promise((resolve, reject)=>{
    //         if (this.auth.user) this.auth.getKey().then((res: Key)=>{
    //             resolve(ecc.sign(this.auth.user.username, res.priv_key))
    //         }, err => reject('Error getting keys'))
    //         else reject('Not logged in')
    //     })
    // }
}

export interface Keys {
    active: Key,
    owner: Key,
}
export interface Key {
    pub_key : string,
    priv_key : string,
}