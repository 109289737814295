import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Account, AuthService } from './auth.service';
import { AbiItem } from "web3-utils";

import { JsonRpc, RpcError, Api } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';
import { createInitialTypes, SerialBuffer } from 'eosjs/dist/eosjs-serialize';
import { TextDecoder, TextEncoder } from 'text-encoding';

import { SystemService } from './system.service';
import { CryptoService } from './crypto.service';
import { Router } from '@angular/router';

import { PushTransactionArgs } from 'eosjs/dist/eosjs-rpc-interfaces';
import { TransactResult } from 'eosjs/dist/eosjs-api-interfaces';
import { AlertController, LoadingController } from '@ionic/angular';
// import { ConnectService } from './connect.service';
const ecc = require('eosjs-ecc')

@Injectable()
export class ContractService {
    private events : any = {};
    public rpc? : JsonRpc;
    public api? : Api;

    public ready: boolean = false;
    private contractName : string = "mk.users";
    private endpoint : string = "https://swamprod.airwire.io";

    private endpoints = [
        "https://swamprod.airwire.io",
        'https://londonprod.airwire.io',
        // 'https://tokyoprod.airwire.io',
        'https://sydneyprod.airwire.io',
        // 'https://fremontprod.airwire.io',
        'https://wire.siliconswamp.info'
    ]
    
    lastAccount: string | undefined;
    maintenance = false

    async last_irreversible_block_num(): Promise<number | undefined> {
		if(this.rpc) {
            let info = await this.rpc.get_info();
            return info.last_irreversible_block_num;
        } else return
	}

    constructor(
        public auth : AuthService,
        private crypto : CryptoService,
        private http : HttpClient,
        public system : SystemService,
        private alert : AlertController,
        private load : LoadingController,
        private router : Router ){

        this.init()
    }

    async init(){
        this.findEndpoint().then((endpoint)=>{
            this.endpoint = endpoint
            if (!this.auth.user) this.login()
            else this.auth.getKey().then((key : Key) => { this.login(key) })
        })
    }

    login(key? : Key){
        let signatureProvider : JsSignatureProvider = new JsSignatureProvider(key ? [key.priv_key] : []);
        let rpc : JsonRpc = this.rpc = new JsonRpc(this.endpoint, { fetch });
        this.api = new Api({rpc, signatureProvider, textDecoder : new TextDecoder(), textEncoder : new TextEncoder()});
        this.ready = true;
        this.emit('contractReady', {});
    }

    async getApiFromKey(priv_key : string, mainnet? : boolean){
        const signatureProvider = new JsSignatureProvider([priv_key]);
        const rpc = new JsonRpc(this.endpoint, { fetch });
        const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
        return api;
    }

    getRows(options: any): Promise<GetRowData>
    getRows<T>(options: any): Promise<GetRows<T>>
    getRows(options : any) {
        return new Promise(async (res, rej) => {
            let defaults: any = {
                // scope: this.contractName, 
                contract: this.contractName, 
                limit: 9999, 
                index: 1,
                reverse: false
            };
            ['scope', 'contract', 'limit', 'index', 'reverse'].forEach((key) => {
                if (!options.hasOwnProperty(key)) options[key] = defaults[key]}
            );     
            // console.log('GET ROWS', options);
                  
            try {
                let result : GetRowData = await this.rpc!.get_table_rows({
                    json: true,
                    code: options.contract,
                    scope: options.scope ? options.scope : options.contract,
                    table: options.table,
                    index_position: options.index_position,
                    limit: options.limit,
                    lower_bound: options.lower_bound,
                    upper_bound: options.upper_bound,
                    key_type: options.key_type,
                    reverse: options.reverse,
                });
                // console.log(result);
                res(result);
            } catch (e) {
                console.log('\nCaught exception on get_table_rows: ', e);
                this.emit('walletError', e);
                if (e instanceof RpcError) rej(JSON.stringify(e.json, null, 2));
            }
        })
    }

    pushTransaction(options: TransactionOptions | TransactionOptions[]): Promise<PushTransactionArgs | TransactResult>{
        return new Promise(async (res : any, rej) => {
            if (!this.maintenance){
                let actions = []

                if(Array.isArray(options)) {
                    for(let option of options) {
                        let { account, name, actor, data  } = option;
                        actions.push({
                            account: account ? account : this.contractName,
                            name: name,
                            authorization: [{
                                actor: actor,
                                permission: 'active'
                            }],
                            data: data
                        })
                    }
                } else {
                    let { account, name, actor, data  } = options;
                    actions.push({
                        account: account ? account : this.contractName,
                        name: name,
                        authorization: [{
                            actor: actor,
                            permission: 'active'
                        }],
                        data: data
                    })
                }
                
                try {
                    const result = await this.api!.transact(
                        { actions }, 
                        { blocksBehind: 3, expireSeconds: 3600 }
                    );

                    this.emit('success', result);
                    // console.log(result);
                    res(result);
                }
                catch (e : any) {
                    console.log('\nCaught exception on transact: ' + e);
                    this.emit('walletError', e);
                    rej(e.toString().replace('Error: assertion failure with message: ', ''))
                }
            }
            else {
                this.system.showToast({ header: "Maintenance Mode", message: "You cannot push transactions at this time. Click the banner below for more info.", color: "warning" })
                this.load.dismiss()
            }
        })
    }

    getUser(username : string, checkEmail? : boolean): Promise<any>{
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'users',
                lower_bound: username,
                upper_bound: username,
                limit: 1
            })
            .then(async (data : GetRowData) => {
                if (data.rows && data.rows.length && data.rows[0].username == username)
                    resolve(data.rows[0])
                else reject({ error: 'User not found' })
            }, (err:any) => { reject(err) })
        })
    }
 

    getAllAccounts() : Promise<Account[]>{
        return new Promise ((resolve, reject)=>{
            let getAccounts  = async (lower_bound? : string) : Promise<Account[]> => {
                let userRow = await this.getRows<Account>({
                    table: 'accounts',
                    key_type: 'name',
                    lower_bound: lower_bound ? this.uint64ToName(lower_bound!) : undefined
                })
                let users = userRow.rows
                if (userRow && userRow.more && userRow.next_key) {
                    let moreUsers = await getAccounts(userRow.next_key)
                    return moreUsers.concat(users)
                }   else return users
            }
            getAccounts().then(async (users)=>{ resolve(users) })
        })
    }
    
    findEndpoint(): Promise<string> {
        let prom = new Promise<string>((resolve, reject) => {
            let proms: Array<Promise<PingResponse>> = []
            for(let ep of this.endpoints) {
                proms.push(new Promise((resolve) => {
                    let start = new Date().getTime();
                    let url = ep + '/v1/chain/get_info';
                    this.http.get(url).subscribe((response) => {
                        let end = new Date().getTime();
                        let ms = end - start;
                        resolve({
                            ms,
                            endpoint: ep
                        })
                    }, err => {
                        // console.log('Error getting info');
                        resolve({
                            ms: undefined,
                            endpoint: ep
                        });
                    })
                }))
            }

            // console.log(proms);

            Promise.all(proms).then((pings) => {
                // console.log('FINISHED');
                let successful = pings.filter(p => p.ms != undefined);
                if(successful.length) {
                    let sorted = successful.sort((a, b) => {return  a.ms && b.ms ? a.ms > b.ms ? 1 : b.ms > a.ms ? -1 : 0 : 0 });
                    // console.log(sorted);
                    // console.log('FOUND', sorted[0].endpoint);
                    
                    resolve(sorted[0].endpoint);
                } else {
                    resolve(this.endpoint);
                }
            })
        })
        // console.log(prom);
        
        return prom;
    }

    uint64ToName(int: string) {
        try {
            const builtinTypes = createInitialTypes()
            const typeUint64 = builtinTypes.get("uint64")
            const typeName = builtinTypes.get("name")
            var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
            typeUint64?.serialize(buffer, int)
            return typeName?.deserialize(buffer)
        } catch { return ''; }
    }

    showError(error : any){
        this.system.emit('toast', { header: "Something went wrong...", message: error.message, color: 'danger' })
    }
    shortenAddress(address : string) {
        const prefix = address.slice(0, 6);
        const suffix = address.slice(-4);
        return `${prefix}...${suffix}`;
    }

    on(event : string) : Subject<any> {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export function makeSingleKeyAuth(key : string) {
	return {
		'threshold': 1,
		'keys': [{'key': key, 'weight': 1}],
		'accounts': [],
		'waits': []
	};
}
export function makeAsset(amount: number | string, symbol: string, precision: number) {
	const value = typeof amount === 'string' ? parseFloat(amount) : amount;
	return `${value.toFixed(precision)} ${symbol}`;
}

export interface GetRows<T> {
    rows : Array<T>,
    more : boolean,
    next_key : string
}
export interface GetRowData {
    rows : Array<any>,
    more : boolean,
    next_key : string
}
export interface getKeys {
    data: Keys,
    msg: string,
    result: number
}
export interface Keys {
    active: Key,
    owner: Key,
}
export interface Key {
    pub_key : string,
    priv_key : string,
}
export interface Action {
    account: string,
    name: string,
    authorization: Auth[],
    data: Object,
}
export interface Auth {
    actor: string,
    permission: string
}
export interface TransactionOptions {
    account?: string;
    name: string;
    actor: string;
    data: any;
}
export interface Permission {
    type: string;
    actor: string;
}
export interface TokenListOptions {
    token?: string;
    meta?: boolean; 
    stat?: boolean;
}
export interface NFTResult {
	more: boolean;
	next_key: string;
	rows: NFTRow[]
}

export interface NFTRow {
	address: string;
	ceil_price: string;
	floor_price: string;
	id: number
	image: string;
	name: string;
	popularity: number;
}
interface PingResponse {
    ms?: number;
    endpoint: string;
}
export interface WireChainUser {
    user: string;
    eth_address?: string;
    nonce: number;
    verified: number;
    metamask_user: boolean;
    added: string | Date;
    modified: string | Date;
}
interface ContractJson {
    _format: string;
    contractName: string;
    souceName: string;
    abi: AbiItem | AbiItem[];
    bytecode: string;
    deployedBytecode: string;
    linkReference: any;
    deployedLinkReferences: any;
}
export interface GroupUser {
    group: string
    permission: 0 | 1 | 2 | 3
}
export interface Link {
    key : number
    link : string
}
export interface LinkDictionary {
    [key : number]: string
}