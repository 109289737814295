import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { SystemService } from './_services/system.service';
import { AuthService } from './_services/auth.service';
import { MessagingService } from './_services/messaging.service';
import { EventService } from './_services/event.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { NotifyService } from './_services/notify.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
	appPages = [
		{
			title: 'Schedule',
			url: '/app/tabs/schedule',
			icon: 'calendar'
		},
		{
			title: 'Speakers',
			url: '/app/tabs/speakers',
			icon: 'people'
		},
		{
            title: 'Chat',
			url: '/app/tabs/chat',
			icon: 'chatbubbles'
		},
        // {
        //     title: 'Map',
        //     url: '/app/tabs/map',
        //     icon: 'map'
        // },
	];

	// loggedIn = false;
	dark = true;



	constructor(
		private menu: MenuController,
		private platform: Platform,
		private router: Router,
		private storage: Storage,
		private userData: UserData,
		private toastCtrl: ToastController,
		public system : SystemService,
		public auth : AuthService,
		private messaging : MessagingService,
        private event : EventService,
        private notify : NotifyService
	) {
		this.initializeApp();
	}

	async ngOnInit() {
		// this.checkLoginStatus();
		this.listenForLoginEvents();
	}

	initializeApp() {
        this.event.init()

		this.platform.ready().then(() => {
            
			// if (this.platform.is('hybrid')) {
				// this.dark = this.system.mode
				// StatusBar.hide();
				SplashScreen.hide();
                // this.setupPushNotifications();
			// }

			if (this.auth.user) {
                this.messaging.init()
                this.notify.init(this.auth.user.username)
            }   
		});
	}

    openMap(){
        this.system.openLink('https://www.outeredge.live/community-events-heat-map')
    }

	// checkLoginStatus() {
	// 	return this.userData.isLoggedIn().then(loggedIn => {
	// 		return this.updateLoggedInStatus(loggedIn);
	// 	});
	// }

	// updateLoggedInStatus(loggedIn: boolean) {
	// 	setTimeout(() => {
	// 		this.loggedIn = loggedIn;
	// 	}, 300);
	// }

    // async setupPushNotifications() {
    //     if (this.platform.is('capacitor')) {
    //         await PushNotifications.requestPermissions();
        
    //         PushNotifications.register();
        
    //         PushNotifications.addListener('registration', (token) => {
    //             console.log('Push registration success, token: ' + token.value);
    //         });
        
    //         PushNotifications.addListener('registrationError', (error) => {
    //             console.error('Error on registration: ' + JSON.stringify(error));
    //         });
        
    //         PushNotifications.addListener('pushNotificationReceived', (notification) => {
    //             console.log('Push received: ' + JSON.stringify(notification));
    //         });
        
    //         PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    //             console.log('Push action performed: ' + JSON.stringify(notification));
    //         });
    //     }
    // }

	listenForLoginEvents() {
		window.addEventListener('user:login', () => {
			// this.updateLoggedInStatus(true);
		});

		window.addEventListener('user:signup', () => {
			// this.updateLoggedInStatus(true);
		});

		window.addEventListener('user:logout', () => {
			// this.updateLoggedInStatus(false);
		});
	}

	logout() {
		this.auth.logout()
		// this.userData.logout().then(() => {
		// 	return this.router.navigateByUrl('/app/tabs/schedule');
		// });
	}

	openIntro() {
		this.menu.enable(false);
		this.storage.set('ion_did_intro', false);
		this.router.navigateByUrl('/intro');
	}

	toggle(e : any){
		this.system.toggleDark(e.detail.checked)
	}
}
